import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle05,
  CInlineDefinition,
  CInlineDefinition02,
  CRestaurantJumbotron,
  LContact02,
  LContact03,
  LStores,
  AssetImage,
  CSimpleSlider ,
} from '../../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/iki/iki_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/iki/cake/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/iki/cake/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge">
        <LWrap>
          <CSectTitle
            title={{
              en: 'CAKES MENU',
              ja: 'メニュー',
            }}
          />
          <CInlineDefinition02
            col={2}
            data={[
              {
                title: 'ホールケーキ',
                titlesub: 'ショートケーキ（5号：15㎝）',
                text: <>4,104円</>,
              },
              {
                title: 'ホールケーキ',
                titlesub: 'ショートケーキ（4号：12㎝）',
                text: <>3,024円</>,
              },
              {
                title: 'ホールケーキ',
                titlesub: 'ストロベリータルト（13㎝）',
                text: <>3,564円</>,
              },
              {
                title: 'ロールケーキ 粋 -IKI-',
                text: '2,160円',
              },
              {
                title: 'モンブラン',
                text: <>702円</>,
              },
              {
                title: 'ショートケーキ',
                text: <>702円</>,
              },
              {
                title: 'フランボワーズショコラ',
                text: <>648円</>,
              },
              {
                title: 'ミルフィーユ',
                text: <>648円</>,
              },
              {
                title: 'クレームカラメル',
                text: '486円',
              },
              {
                title: 'シュークリーム',
                text: '378円',
              },
              {
                title: 'カヌレ',
                text: <>324円</>,
              },
            ]}
          />
          <ul className="c_noteList">
            <li>
              季節や入荷状況により、メニュー内容が変更になる場合がございます。
            </li>
            <li>売り切れの際はご容赦ください。</li>
            <li>料金には消費税(8％)が含まれます。</li>
            <li>掲載の写真は実際と異なる場合がございます。</li>
          </ul>
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle
            title={{
              en: 'ANNIVERSARY CAKE',
              ja: 'アニバーサリーケーキ',
            }}
          />
          <CSimpleSlider
              width="1040px"
              exClass="u_mb30"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/iki/cake/img_iki_cake.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/cake/img_iki_cake__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/iki/cake/img_iki_cake02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/cake/img_iki_cake02__sp.png',
                    alt: '',
                  },
                },
              ]}
            />

          <div className="c_inlineDefinitionUnit03 c_inlineDefinitionUnit03__col2 u_mb0">
            <div className="col">
              <h3 className="u_mb10 u_fwb">ショートケーキ（ホールサイズ）</h3>
              <CInlineDefinition
                col={1}
                data={[
                  {
                    title: '4号（12cm　約3名様分）',
                    text: <>3,024円</>,
                  },
                  {
                    title: '5号（15cm　約8名様分）',
                    text: <>4,104円</>,
                  },
                  {
                    title: '6号（18cm　約10名様分）',
                    text: <>5,400円</>,
                  },
                  {
                    title: '7号（21cm　約12名様分）',
                    text: <>7,128円</>,
                  },
                  {
                    title: '8号（24cm　約16名様分）',
                    text: <>9,180円</>,
                  },
                ]}
              />
            </div>
            <div className="col">
              <h3 className="u_mb10 u_fwb">
                ショートケーキ（ホールサイズ） <br />
                フルーツデコレーション
              </h3>
              <CInlineDefinition
                col={1}
                data={[
                  {
                    title: '4号（12cm　約3名様分）',
                    text: <>3,780円</>,
                  },
                  {
                    title: '5号（15cm　約8名様分）',
                    text: <>5,184円</>,
                  },
                  {
                    title: '6号（18cm　約10名様分）',
                    text: <>6,804円</>,
                  },
                  {
                    title: '7号（21cm　約12名様分）',
                    text: <>8,964円</>,
                  },
                  {
                    title: '8号（24cm　約16名様分）',
                    text: <>11,556円</>,
                  },
                ]}
              />
            </div>
          </div>
          <ul className="c_noteList">
            <li>料金にはプレート代金は含まれておりません。</li>
            <li>
              クリスマス期間は、クリスマスケーキのみの販売となりますのでご了承ください。
            </li>
            <li>料金には消費税（8％）が含まれます。</li>
            <li>
              お部屋にご用意をする場合は料金が異なります。詳しくは
              <a href="/private/zxgej758veg/">こちら</a>をご確認ください。
            </li>
            <li>掲載の写真は実際と異なる場合がございます。</li>
          </ul>
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle05 title={'ご注文について'} exClass="u_mb20" />
          <ul className="c_circleList u_mbMedium">
            <li>4号～7号：お渡し日の4日前まで</li>
            <li>8号以上：お渡し日の10日前まで</li>
          </ul>
          <CSectTitle05 title={'オプション'} exClass="u_mb20" />
          <ul className="c_circleList">
            <li>バースデープレート：324円（4号、5号）／648円（6号、7号）</li>
            <li>ナンバーキャンドル：1個 154円</li>
            <li>棒ローソク（大、小）：無料</li>
          </ul>
          <ul className="c_noteList u_mbMedium u_mt10">
            <li>料金には消費税が含まれます。</li>
          </ul>
          <CSectTitle05 title={'その他'} exClass="u_mb20" />
          <ul className="c_circleList u_mbMedium">
            <li>
              生クリームはチョコレートタイプに変更できます（料金10％追加）。
            </li>
            <li>
              スポンジはチョコレートスポンジに変更できます（料金10％追加）。
            </li>
            <li>
              9号、10号サイズやスクエア型もご用意いたしますので、詳しくはお問合せください。
            </li>
          </ul>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
